import { gql } from 'apollo-boost'
import { StoreModel } from './index'
import { graphQL } from '@mortgage-pos/ui/services/http'
import { save } from './thunks/save.thunk'
import incense from '@mortgage-pos/ui/incense'
import { CreditReportType } from '@mortgage-pos/types'
import { getGraphQLString } from '@mortgage-pos/utils'
import { action, Action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy'
import ApplicationClient from '@mortgage-pos/ui/services/ApplicationClient'
import { ExperienceType, LeadSourceType } from '@mortgage-pos/types'
import {
  identifyApplication,
  setUserVars,
} from '@mortgage-pos/ui/utils/fullStory'
import { captureAlfalfaLeadSource } from '@mortgage-pos/ui/utils/applicationHelpers'
import { FEATURE_FLAG_TYPES } from '@mortgage-pos/data'
import { variation } from '@mortgage-pos/ui/services/featureFlags'

export interface ApplicationModel {
  applicationId: number
  applicationGuid: string
  applicationCreatedAt: Date
  coBorrowerId: number
  setApplicationId: Action<ApplicationModel, number>
  setApplicationGuid: Action<ApplicationModel, string>
  setApplicationCreatedAt: Action<ApplicationModel, Date>
  setCoBorrowerId: Action<ApplicationModel, number>
  initialize: Thunk<ApplicationModel, null, object, StoreModel>
  softPullCredit: Thunk<ApplicationModel, null, null, StoreModel>
  coreLogicSoftPullCredit: Thunk<ApplicationModel, null, null, StoreModel>
  hardPullCredit: Thunk<ApplicationModel, null, null, StoreModel>
  compareCreditScores: Thunk<ApplicationModel, null, null, StoreModel>
  save: Thunk<ApplicationModel, any, null, StoreModel>
  updateStatus: Thunk<ApplicationModel, string, null, StoreModel>
  setExperienceType: Thunk<ApplicationModel, string>
  handleApplicationSession: ThunkOn<ApplicationModel, null, StoreModel>
}

const application = (): ApplicationModel => {
  return {
    applicationId: null,

    applicationGuid: null,

    applicationCreatedAt: null,

    coBorrowerId: null,

    setApplicationId: action((state, payload) => {
      setUserVars({ applicationId_int: payload })
      state.applicationId = payload
    }),

    handleApplicationSession: thunkOn(
      (actions, storeActions) => [
        actions.setApplicationId,
        storeActions.questionnaire.setSlug,
      ],
      async (actions, target, { getState, getStoreState }) => {
        try {
          const { applicationId } = getState()
          const { slug } = getStoreState().questionnaire

          if (applicationId && slug) {
            await ApplicationClient.fillSession(slug)
          }
        } catch (e) {
          incense(e)
            .details({
              name: 'ApplicationStore',
              message: 'error during handleApplicationSession thunkOn',
            })
            .sensitive({ target })
            .error()
        }
      }
    ),

    setApplicationGuid: action((state, payload) => {
      identifyApplication(payload)
      setUserVars({ applicationGuid_str: payload })
      state.applicationGuid = payload
    }),

    setApplicationCreatedAt: action((state, payload) => {
      state.applicationCreatedAt = payload
    }),

    setCoBorrowerId: action((state, payload) => {
      setUserVars({ coBorrowerId_int: payload })
      state.coBorrowerId = payload
    }),

    initialize: thunk(
      async (
        { setApplicationId, setApplicationGuid },
        _,
        { getStoreState }
      ) => {
        const {
          bankrateLeadId,
          fBLeadId,
          referrerUrl,
          landingUrl,
          isPhoneFlow,
          directMailerOfferCode,
        } = getStoreState().questionnaire
        const { isLeComparisonApp } = getStoreState().leComparison

        const urlParams = new URLSearchParams(window.location.search)
        const loValue = urlParams.get('loan-officer-id')
        const loanOfficerId = loValue ? Number(loValue) : null

        const phoneAppGuid = urlParams.get('application-guid')
        const isPhoneFlowEnabled = await variation(
          FEATURE_FLAG_TYPES.PHONE_FLOW_ENABLED
        )

        if (isPhoneFlow && isPhoneFlowEnabled && phoneAppGuid) {
          const response = await getAppIdFromGuid(phoneAppGuid)

          setApplicationId(response?.data?.data?.getApplicationIdFromGuid)
          return
        }

        const { applicationId, applicationGuid } =
          await ApplicationClient.initialize(
            bankrateLeadId,
            loanOfficerId,
            fBLeadId,
            getLeadSource(isLeComparisonApp, directMailerOfferCode),
            landingUrl,
            directMailerOfferCode,
            referrerUrl
          )

        setApplicationId(applicationId)
        setApplicationGuid(applicationGuid)
      }
    ),

    softPullCredit: thunk(
      async (_, __, { getState, getStoreActions, getStoreState }) => {
        const { applicationId } = getState()
        const { isSplitFlow } = getStoreState().questionnaire
        const { setMortgageRateEstimate } = getStoreActions().bankrateCompare

        const { setHasRunCreditPull, setWasCreditPullSuccessful } =
          getStoreActions().leComparison

        const { answer } = getStoreActions().questionnaire

        // For compare flow
        setHasRunCreditPull(true)

        const res = await ApplicationClient.softPullCreditScore(
          applicationId,
          !!isSplitFlow
        )

        if (res?.mortgageRateEstimate) {
          const { openedDate, paymentScheduleMonthCount, currentBalance } =
            res.mortgageRateEstimate

          setMortgageRateEstimate(res.mortgageRateEstimate)

          await answer({
            currentLoanStartDate: openedDate,
            currentLoanTerm: paymentScheduleMonthCount / 12,
            remainingBalance: currentBalance,
          })
        }

        // Needed for compare flow to determine if we should use a fallback score
        setWasCreditPullSuccessful(!!res?.success)

        return res?.success
      }
    ),

    // Currently not in use
    coreLogicSoftPullCredit: thunk(async (_, __, { getState }) => {
      const { applicationId } = getState()
      await ApplicationClient.pullCredit(
        applicationId,
        CreditReportType.Prequal
      )
    }),

    hardPullCredit: thunk(async (_, __, { getState }) => {
      const { applicationId } = getState()
      await ApplicationClient.pullCredit(
        applicationId,
        CreditReportType.Trimerge
      )
    }),

    compareCreditScores: thunk(async (_, __, { getState }) => {
      const { applicationId } = getState()
      return await ApplicationClient.compareCreditScores(applicationId)
    }),

    save: save(),

    updateStatus: thunk(async (_, status, { getState }) => {
      const { applicationId } = getState()
      await ApplicationClient.updateQStatus(applicationId, status)
    }),

    setExperienceType: thunk(
      async (_, experienceType: ExperienceType, { getState }) => {
        const { applicationId } = getState()

        try {
          await graphQL({
            query: setExperienceTypeMutation,
            variables: {
              applicationId: null,
              experienceType: experienceType as string,
            },
          })
        } catch (error) {
          incense(error)
            .details({
              name: 'SetExperienceTypeError',
              message: 'Failed to set experience type on application',
            })
            .safe({ applicationId })
            .error()
        }
      }
    ),
  }
}

// TODO: remove applicationId from query/mutation
export const setExperienceTypeMutation = getGraphQLString(gql`
  mutation setExperienceType(
    $applicationId: Int
    $experienceType: ExperienceType!
  ) {
    setExperienceType(
      applicationId: $applicationId
      experienceType: $experienceType
    )
  }
`)

function getLeadSource(isLeComparisonApp, directMailerOfferCode) {
  let leadSource = null

  if (isLeComparisonApp) {
    leadSource = LeadSourceType.LoanEstimateComparison
  } else if (directMailerOfferCode) {
    leadSource = LeadSourceType.Transunion
  }

  return leadSource || captureAlfalfaLeadSource()
}

export default application

async function getAppIdFromGuid(applicationGuid: string) {
  return await graphQL({
    query: getAppIdFromGuidQuery,
    variables: {
      applicationGuid,
    },
  })
}
export const getAppIdFromGuidQuery = getGraphQLString(gql`
  query getApplicationIdFromGuid($applicationGuid: String!) {
    getApplicationIdFromGuid(applicationGuid: $applicationGuid)
  }
`)
