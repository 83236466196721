import { gql } from 'apollo-boost'
import { StoreModel } from './index'
import newRelic from '@mortgage-pos/ui/services/newRelic'
import { graphQL } from '@mortgage-pos/ui/services/http'
import incense from '@mortgage-pos/ui/incense'
import { rateCodesMap } from '@mortgage-pos/data'
import { action, Action, Computed, computed, thunk, Thunk } from 'easy-peasy'

import {
  hasAxiosErrors,
  getGraphQLString,
  LoanCostsCalculator,
  deriveLoanTermInMonths,
  extractFeeByDescription,
  calculatePurchaseLoanToValue,
  calculateRefinanceLoanToValue,
} from '@mortgage-pos/utils'

import {
  Rate,
  RateInput,
  LoanPurpose,
  RatesRequest,
  RatesRequestSource,
  LockRequestType,
} from '@mortgage-pos/types'

export interface RatesModel {
  rates: Rate[]
  selectedRate: Rate
  isRequestingRates: boolean
  debtToIncome: number
  setDebtToIncome: Action<RatesModel, number>
  loanToValue: Computed<RatesModel, number, StoreModel>
  sortedRates: Computed<RatesModel, Rate[], StoreModel>
  setRates: Action<RatesModel, Rate[]>
  setSelectedRate: Action<RatesModel, Rate>
  requestRates: Thunk<RatesModel, number | undefined, object, StoreModel>
  setIsRequestingRates: Action<RatesModel, boolean>
  submitRateSelection: Thunk<RatesModel, Rate, object, StoreModel>
  submitLockForm: Thunk<RatesModel, Rate, object, StoreModel>
  lockWipLockForm: Thunk<RatesModel, null, object, StoreModel>
}

const rates = (): RatesModel => {
  return {
    rates: [],

    selectedRate: null,

    isRequestingRates: false,

    debtToIncome: null,

    setDebtToIncome: action((state, payload) => {
      state.debtToIncome = payload
    }),

    loanToValue: computed(
      [
        (_, storeState) => storeState.answers.mergedAnswers,
        (_, storeState) => storeState.propertyInfo.avmEstimatedValue,
        (_, storeState) => storeState.rates.rates,
      ],
      (mergedAnswers, avmEstimatedValue, storedRates) => {
        if (mergedAnswers.loanPurpose === LoanPurpose.Purchase) {
          const { propertyPurchasePrice, propertyDownPayment } = mergedAnswers
          const hasPurchaseFields =
            !isNaN(propertyPurchasePrice) && !isNaN(propertyDownPayment)

          if (!hasPurchaseFields) {
            return 100
          }

          return calculatePurchaseLoanToValue(
            propertyPurchasePrice,
            propertyDownPayment
          )
        }

        const estimatedValue = mergedAnswers.estimatedValue || avmEstimatedValue

        if (!estimatedValue) {
          return 100
        }

        const { balanceWithFees } = getLoanAmounts(mergedAnswers, storedRates)

        return calculateRefinanceLoanToValue(
          balanceWithFees,
          estimatedValue,
          mergedAnswers.cashOut
        )
      }
    ),

    sortedRates: computed((state) => {
      // per biz, only targeting 30, 20, 15 year fixed rates
      const filteredRates = state.rates.filter(
        (rate) => rate.type === 'Fixed' && rate.term !== 'F40'
      )

      const sortWeights = {
        F30: 1,
        F25: 2,
        F20: 3,
        F15: 4,
        F10: 5,
        F40: 6,
        A1_1: 7,
        A3_1: 8,
        A5_1: 9,
        A2_1: 10,
        A5_5: 11,
        A7_1: 12,
        A10_1: 13,
        A15_1: 14,
      }

      return filteredRates.sort((a, b) => {
        return sortWeights[a.term] - sortWeights[b.term]
      })
    }),

    setRates: action((state, payload) => {
      state.rates = payload
    }),

    setSelectedRate: action((state, payload) => {
      state.selectedRate = payload
    }),

    setIsRequestingRates: action((state, payload) => {
      state.isRequestingRates = payload
    }),

    requestRates: thunk(
      async (
        { setIsRequestingRates, setRates },
        debtToIncome = undefined,
        { getState, getStoreState, getStoreActions }
      ) => {
        const { loanToValue, rates: storedRates } = getState()
        const { mergedAnswers } = getStoreState().answers
        const { applicationId } = getStoreState().application
        const { statusesMap } = getStoreState().questionnaire
        const { avmEstimatedValue } = getStoreState().propertyInfo

        const { hasRunCreditPull, isLeComparisonApp, wasCreditPullSuccessful } =
          getStoreState().leComparison

        const { updateStatus } = getStoreActions().application

        setIsRequestingRates(true)

        // If user provided a credit score estimate, we can init w/ that value
        // (compare flow only for now)
        let creditScoreOverride: number = isLeComparisonApp
          ? mergedAnswers.creditScore
          : null

        // Use fallback credit score in compare flow if we couldn't pull credit
        if (isLeComparisonApp && hasRunCreditPull && !wasCreditPullSuccessful) {
          creditScoreOverride = creditScoreOverride ?? 740
        }

        let resp

        try {
          resp = await getRates(
            mergedAnswers,
            loanToValue,
            applicationId,
            avmEstimatedValue,
            storedRates,
            debtToIncome,
            creditScoreOverride
          )
        } catch (error) {
          await updateStatus(statusesMap.noRates)
          newRelic.increment('rates_request.error')
          setIsRequestingRates(false)

          incense(error)
            .details({
              name: 'RequestRatesFailure',
              message: 'Failed to fetch rates',
            })
            .safe({ applicationId, loanToValue, debtToIncome })
            .sensitive({
              mergedAnswers,
              avmEstimatedValue,
              creditScoreOverride,
            })
            .error()
            .rethrow()
        }

        if (resp.data.errors && resp.data.errors.length) {
          await updateStatus(statusesMap.noRates)

          newRelic.increment('rates_request.error')
          setIsRequestingRates(false)

          incense(resp.data.errors)
            .details({
              name: 'RequestRatesFailure',
              message: 'Failed to fetch rates',
            })
            .safe({ applicationId, loanToValue, debtToIncome })
            .sensitive({
              mergedAnswers,
              avmEstimatedValue,
              creditScoreOverride,
            })
            .error()
            .rethrow()
        }

        const rates = resp.data.data.getRates

        if (rates.length) {
          await updateStatus(statusesMap.rates)

          newRelic.increment('rates_request.success', [
            { key: 'count', value: rates.length.toString() },
          ])
        } else {
          await updateStatus(statusesMap.noRates)

          newRelic.increment('rates_request.no_rates')
        }

        setRates(rates)
        setIsRequestingRates(false)
        return rates
      }
    ),

    submitRateSelection: thunk(async ({ setSelectedRate }, rate: Rate) => {
      setSelectedRate(rate)

      let response
      let rateInput: RateInput

      try {
        rateInput = rateToRateInput(rate)

        response = await graphQL({
          query: submitRateSelectionQuery,
          variables: { applicationId: null, rate: rateInput },
        })
      } catch (error) {
        newRelic.increment('q_apply.rates_submission.error')

        incense(error)
          .details({
            name: 'SubmitRateSelectionFailure',
            message: 'Failed to fetch rates',
          })
          .sensitive({ rate, rateInput })
          .error()

        return
      }

      if (hasAxiosErrors(response)) {
        newRelic.increment('q_apply.rates_submission.error')

        incense(response.data.errors)
          .details({
            name: 'SubmitRateSelectionFailure',
            message: 'Request errors occurred during execution',
          })
          .sensitive({ rate, rateInput })
          .error()

        return
      }

      newRelic.increment('q_apply.rates_submission.success')
    }),

    submitLockForm: thunk(
      async (_, rate: Rate, { getStoreState, getState, getStoreActions }) => {
        const { rates: storedRates } = getState()
        const { debtToIncome } = getStoreState().rates
        const { applicationId } = getStoreState().application
        const { mergedAnswers } = getStoreState().answers
        const { isPrePricingFlow } = getStoreState().questionnaire
        const { remainingBalance } = getLoanAmounts(mergedAnswers, storedRates)
        const { setSelectedRate } = getStoreActions().rates

        let response
        let gqlVars: Record<string, any> = {}

        setSelectedRate(rate)

        const handleErrors = async (error, step = '', message = '') => {
          newRelic.increment('ntl.submit_lock_form.error', [
            { key: 'failureStep', value: step },
          ])

          await graphQL({
            query: triggerLockDeskFailureAlert,
            variables: { applicationId: null },
          })

          incense(error)
            .details({
              name: 'SubmitLockFormError',
              message,
            })
            .safe({ applicationId })
            .sensitive({ gqlVars })
            .error()
        }

        try {
          gqlVars = {
            applicationId: null,
            rate: rateToRateInput(rate),
            pricingScenarioInfo: {
              remainingBalance: Math.round(remainingBalance), // GQL expects int
              debtToIncome,
            },
            lockRequestType: isPrePricingFlow
              ? LockRequestType.Wip
              : LockRequestType.Lock,
          }

          response = await graphQL({
            query: submitLockFormQuery,
            variables: gqlVars,
          })
        } catch (error) {
          await handleErrors(
            error,
            'GQL Response',
            'Failed on lock form GQL request'
          )
          return
        }

        if (hasAxiosErrors(response)) {
          await handleErrors(
            response.data.errors,
            'Axios error',
            'Failed on lock form HTTP request'
          )
          return
        }

        newRelic.increment('ntl.submit_lock_form.success')
      }
    ),

    lockWipLockForm: thunk(async (_, __, { getStoreState, getState }) => {
      const { applicationId } = getStoreState().application

      let response

      const handleErrors = async (error, step = '') => {
        newRelic.increment('ntl.lock_wip_lock_form.error', [
          { key: 'failureStep', value: step },
        ])

        await graphQL({
          query: triggerLockDeskFailureAlert,
          variables: { applicationId: null },
        })

        incense(error)
          .details({
            name: 'LockWipLockFormError',
            message: 'Failed on lock form GQL request',
          })
          .safe({ applicationId })
          .error()
      }

      try {
        response = await graphQL({
          query: lockWipLockFormQuery,
        })
      } catch (error) {
        await handleErrors(error, 'GQL Response')
        return
      }

      if (hasAxiosErrors(response)) {
        await handleErrors(response.data.errors, 'Axios error')
        return
      }

      newRelic.increment('ntl.lock_wip_lock_form.success')
    }),
  }
}

export default rates

async function getRates(
  answers,
  loanToValue,
  applicationId,
  avmEstimatedValue,
  storedRates,
  debtToIncome,
  creditScoreOverride?: number
) {
  const {
    remainingBalance,
    isRollInEscrow,
    escrowFees,
    isRollInFees,
    rollInFees,
  } = getLoanAmounts(answers, storedRates)

  const request: RatesRequest = {
    loanToValue,
    loanPurpose: answers.loanPurpose ?? LoanPurpose.Refinance,
    propertyType: answers.propertyType,
    propertyUse: answers.propertyUse,
    zipCode: answers.zipCode,
    address: answers.street,
    state: answers.state,
    city: answers.city,
    rollInFees: isRollInFees ? rollInFees : 0,
    rollInEscrow: isRollInEscrow ? escrowFees : 0,
    willRollInFees: isRollInFees,
    willRollInEscrow: isRollInEscrow,
    waiveEscrow: answers.escrow === 'Yes',
    source: RatesRequestSource.Full1003,
    selfEmployed: checkForSelfEmployedBorrower(answers),
    applicationId: applicationId,
  }

  if (creditScoreOverride) {
    request.creditScore = creditScoreOverride
  }

  if (answers.loanPurpose === LoanPurpose.Purchase) {
    request.propertyPurchasePrice = answers.propertyPurchasePrice
    request.propertyDownPayment = answers.propertyDownPayment
  } else {
    request.propertyValue = answers.estimatedValue || avmEstimatedValue
    request.remainingBalance = remainingBalance
    request.cashOut = answers.cashOut || null
  }

  if (debtToIncome) {
    request.debtToIncome = Math.floor(debtToIncome)
  }

  return graphQL({
    query: ratesQuery,
    variables: { request },
  })
}

export function checkForSelfEmployedBorrower(answers) {
  const primaryIncome = answers.incomeSources || []
  const coBorrowerIncome = answers.coBorrowerIncomeSources || []
  const incomeSources = [...primaryIncome, ...coBorrowerIncome]

  return (
    incomeSources.length > 0 &&
    incomeSources.some((source) => source.incomeType === 'selfEmployed')
  )
}

export interface LoanAmounts {
  remainingBalance: number
  isRollInEscrow: boolean
  escrowFees: number
  isRollInFees: boolean
  rollInFees: number
  balanceWithFees: number
}

export function getLoanAmounts(answers, storedRates): LoanAmounts {
  const {
    cashOut,
    rollInFees: willRollInFees,
    escrowRollIn,
    remainingBalance,
    escrow: waiveEscrow,
    previouslySelectedRate,
  } = answers

  let escrowFeesAmount = 0
  let rollInFeesAmount = 0
  let matchedRate: Rate = {}

  if (storedRates.length) {
    const bankrateSelectedRate = storedRates.find(({ rate, term }) => {
      const termName = rateCodesMap[term]
      const termInMonths = deriveLoanTermInMonths(termName)

      return (
        rate === previouslySelectedRate?.rate &&
        termInMonths === previouslySelectedRate?.termInMonths
      )
    })

    if (bankrateSelectedRate) {
      matchedRate = bankrateSelectedRate
    } else {
      const termSelected = previouslySelectedRate?.termInMonths || 360

      const lowestRate = storedRates.find(({ term }) => {
        const termName = rateCodesMap[term]
        const termInMonths = deriveLoanTermInMonths(termName)

        return termInMonths === termSelected
      })
      matchedRate = lowestRate
    }

    if (matchedRate?.loanEstimateSections) {
      const loanCostsCalculator = new LoanCostsCalculator(
        {
          cashOut,
          totalFees: matchedRate.loanEstimateSections?.['D']?.total,
          totalEscrow: matchedRate.loanEstimateSections?.['G']?.total,
          lenderCredit: extractFeeByDescription(
            matchedRate.loanEstimateSections?.['J']?.fees,
            'Lender Credit'
          ),
          originationFee: extractFeeByDescription(
            matchedRate.loanEstimateSections?.['A']?.fees,
            'Loan origination fee'
          ),
          taxAndPrepaids:
            matchedRate.loanEstimateSections?.['E']?.total +
            matchedRate.loanEstimateSections?.['F']?.total,
          remainingBalance,
        },
        {
          waiveEscrow: waiveEscrow === 'Yes',
          escrowRollIn: escrowRollIn === 'Yes',
          willRollInFees: willRollInFees === 'Yes',
        }
      )
      const loanCosts = loanCostsCalculator.getLoanCosts()

      escrowFeesAmount =
        loanCosts.financedCostsDistribution.totalEscrow +
          loanCosts.financedCostsDistribution.taxAndPrepaids ?? 0

      rollInFeesAmount = loanCosts.financedCostsDistribution.totalFees ?? 0
    }
  }

  return {
    remainingBalance,
    isRollInEscrow: escrowRollIn === 'Yes',
    isRollInFees: willRollInFees === 'Yes',
    escrowFees: Math.round(escrowFeesAmount),
    rollInFees: Math.round(rollInFeesAmount),
    balanceWithFees: Math.round(
      remainingBalance + escrowFeesAmount + rollInFeesAmount
    ),
  }
}

export const ratesQuery = getGraphQLString(gql`
  fragment loanEstimateSection on Section {
    total
    fees {
      description
      amount
    }
  }

  query getRates($request: RatesRequest) {
    getRates(request: $request) {
      adjustments {
        adjustmentType
        adjustmentSourceType
        isHidden
        isStop
        amount
        title
        isCompAdjustment
        filter
      }
      adjustmentsTotal
      apr
      cashOut
      combinedLoanToValue
      discountOrCreditAmount
      discountPoints
      discountPointsAsDollarAmount
      fewerUpfrontCosts
      escrowFees
      family
      fees {
        description
        amount
      }
      finalPrice
      isRollInEscrow
      lenderName
      loanAmount
      loanEstimateSections {
        A {
          ...loanEstimateSection
        }
        B {
          ...loanEstimateSection
        }
        C {
          ...loanEstimateSection
        }
        D {
          ...loanEstimateSection
        }
        E {
          ...loanEstimateSection
        }
        F {
          ...loanEstimateSection
        }
        G {
          ...loanEstimateSection
        }
        H {
          total
        }
        I {
          ...loanEstimateSection
        }
        J {
          ...loanEstimateSection
        }
        Others {
          ...loanEstimateSection
        }
      }
      loanPurpose
      loanTerm
      loanToValue
      lockPeriod
      monthlyEscrowFees
      monthlyInsurance
      monthlyPayment
      monthlyPropertyTax
      mortgageInsurancePremium {
        annualPercentageRate
        annualDollarPremium
        monthlyPercentageRate
        monthlyDollarPremium
        upfrontPercentageRate
        upfrontDollarPremium
        company
        adjustments
      }
      originationPoints
      points
      principleAndInterestPayment
      productClass
      propertyValue
      quoteType
      rate
      rollInFees
      silkFees {
        description
        amount
        lineNumber
      }
      subordinateLienAmount
      term
      totalLenderFees
      type
    }
  }
`)

// TODO: remove applicationId from query/mutation
export const submitRateSelectionQuery = getGraphQLString(gql`
  mutation submitRateSelection($applicationId: Int, $rate: RateInput!) {
    submitRateSelection(applicationId: $applicationId, rate: $rate)
  }
`)

// TODO: remove applicationId from query/mutation
export const submitLockFormQuery = getGraphQLString(gql`
  mutation submitLockForm(
    $applicationId: Int
    $rate: RateInput!
    $pricingScenarioInfo: PricingScenarioInfoInput!
    $lockRequestType: LockRequestType
  ) {
    submitLockForm(
      applicationId: $applicationId
      rate: $rate
      pricingScenarioInfo: $pricingScenarioInfo
      lockRequestType: $lockRequestType
    )
  }
`)

export const lockWipLockFormQuery = getGraphQLString(gql`
  mutation lockWipLockForm {
    lockWipLockForm
  }
`)

// TODO: remove applicationId from query/mutation
export const triggerLockDeskFailureAlert = getGraphQLString(gql`
  mutation sendLockDeskFailureSlackAlert($applicationId: Int) {
    sendLockDeskFailureSlackAlert(applicationId: $applicationId)
  }
`)

export const rateToRateInput = (rate: Rate): RateInput => ({
  adjustments: rate.adjustments,
  adjustmentsTotal: rate.adjustmentsTotal,
  apr: rate.apr,
  borrowerName: rate.borrowerName,
  cashOut: rate.cashOut,
  combinedLoanToValue: rate.combinedLoanToValue,
  discountOrCreditAmount: rate.discountOrCreditAmount,
  discountPoints: rate.discountPoints,
  discountPointsAsDollarAmount: rate.discountPointsAsDollarAmount,
  escrowFees: rate.escrowFees,
  isRollInEscrow: rate.isRollInEscrow,
  family: rate.family,
  fees: rate.fees,
  finalPrice: rate.finalPrice,
  lenderName: rate.lenderName,
  loanAmount: rate.loanAmount,
  loanEstimateSections: rate.loanEstimateSections,
  loanPurpose: rate.loanPurpose,
  loanTerm: rate.loanTerm,
  loanToValue: rate.loanToValue,
  lockPeriod: rate.lockPeriod,
  monthlyEscrowFees: rate.monthlyEscrowFees,
  monthlyInsurance: rate.monthlyInsurance,
  monthlyPayment: rate.monthlyPayment,
  monthlyPropertyTax: rate.monthlyPropertyTax,
  mortgageInsurancePremium: rate.mortgageInsurancePremium,
  originationPoints: rate.originationPoints,
  points: rate.points,
  principleAndInterestPayment: rate.principleAndInterestPayment,
  propertyValue: rate.propertyValue,
  productClass: rate.productClass,
  quoteType: rate.quoteType,
  rate: rate.rate,
  rollInFees: rate.rollInFees,
  silkFees: rate.silkFees,
  subordinateLienAmount: rate.subordinateLienAmount,
  term: rate.term,
  totalLenderFees: rate.totalLenderFees,
  type: rate.type,
})
